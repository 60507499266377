
































import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Markdown from '@/components/Markdown.vue'
import 'simplebar'
import ButtonIcon from '@/components/ButtonIcon.vue'
import IconBurger from '@/assets/img/icons/burger.svg?sprite'
import IconCross from '@/assets/img/icons/cross.svg?sprite'
import Color from '@/constants/color'
import gsap from 'gsap'
import { Getter } from 'vuex-class'

@Component({
    components: {
        Markdown,
        ButtonIcon,
        IconBurger,
        IconCross
    }
})
export default class CheatSheet extends Vue {
    @Prop() content!: string | undefined

    @Getter navigationIsAllowed!: boolean

    isOpen = false
    openColor = Color.LIGHT
    closeColor = Color.DARK

    beforeDestroy() {
        this.removeListeners()
    }

    addListeners() {
        window.addEventListener('keyup', this.onKeyUp)
    }

    removeListeners() {
        window.removeEventListener('keyup', this.onKeyUp)
    }

    open() {
        this.isOpen = true
    }

    close() {
        this.isOpen = false
    }

    onOpenClick() {
        this.open()
    }

    onCloseClick() {
        this.close()
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.key.toLowerCase() === 'escape') this.close()
    }

    onOverlayClick() {
        this.close()
    }

    onContentEnter(element: HTMLElement, done: Function) {
        gsap.from(element, {
            xPercent: 100,
            duration: 1,
            ease: 'power3',
            onComplete: done as GSAPCallback
        })
    }

    onContentLeave(element: HTMLElement, done: Function) {
        gsap.to(element, {
            xPercent: 100,
            duration: 1,
            ease: 'power3',
            onComplete: done as GSAPCallback
        })
    }

    onCloseEnter(element: HTMLElement, done: Function) {
        gsap.from(element, {
            scale: 0.7,
            opacity: 0,
            duration: 0.7,
            delay: 0.4,
            ease: 'power3',
            onComplete: done as GSAPCallback
        })
    }

    onCloseLeave(element: HTMLElement, done: Function) {
        gsap.to(element, {
            scale: 0.7,
            opacity: 0,
            duration: 0.3,
            ease: 'power3',
            onComplete: done as GSAPCallback
        })
    }

    @Watch('isOpen')
    onIsOpenChange() {
        if (this.isOpen) {
            this.addListeners()
        } else {
            this.removeListeners()
        }
    }
}
