
        module.exports = {
          toString: function () {
            return '/img/sprite.7ed3381f.svg#cross--sprite';
          },
          render: function(h) {
            return h('svg', { attrs: { viewBox: "0 0 15 15" } }, [
              h('use', { attrs: { 'xlink:href': '/img/sprite.7ed3381f.svg#cross--sprite' } })
            ]);
          }
        };
    